// i18next-extract-mark-ns-start Step1
export interface Country {
  id: string;
  name: string;
  zipcodeChecker: (_zipcode: string) => boolean;
  zipcodeHasLetters?: boolean;
  zipcodePlaceholder: string;
  phonePrefix: string;
  phoneLength: number;
  unavailable?: boolean;
}

const getCountries = (t: (_str: string) => string): Country[] => [
  {
    id: 'FR',
    name: t('France'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{5}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: '75001 or 69008',
    phonePrefix: '33',
    phoneLength: 9,
  },
  {
    id: 'BE',
    name: t('Belgium'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{4}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 1000 or 4000',
    phonePrefix: '32',
    phoneLength: 8,
  },
  {
    id: 'CH',
    name: t('Switzerland'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{4}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 12O1 or 8000',
    phonePrefix: '41',
    phoneLength: 9,
    unavailable: true,
  },
  {
    id: 'DE',
    name: t('Germany'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{5}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 10115 or 80331',
    phonePrefix: '49',
    phoneLength: 9,
  },
  {
    id: 'LU',
    name: t('Luxembourg'),
    zipcodeChecker: (zipcode) => zipcode?.length === 4,
    zipcodePlaceholder: 'e.g. 1445 or 1208',
    phonePrefix: '352',
    phoneLength: 8,
  },
  {
    id: 'NL',
    name: t('Netherlands'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{4}\s?[A-Z]{0,2}$/;
      return regex.test(zipcode);
    },
    zipcodeHasLetters: true,
    zipcodePlaceholder: 'e.g. 1012 AB or 1017',
    phonePrefix: '31',
    phoneLength: 9,
  },
  {
    id: 'UK',
    name: t('United Kingdom'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]?\s?[0-9][A-Z]{2}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. SW1A 1AA or SW1W 0NY',
    phonePrefix: '44',
    phoneLength: 10,
    unavailable: true,
  },
  {
    id: 'IT',
    name: t('Italy'),
    zipcodeChecker: (zipcode) => zipcode?.length === 5,
    zipcodePlaceholder: 'e.g. 00118 or 20121',
    phonePrefix: '39',
    phoneLength: 10,
  },
  {
    id: 'ES',
    name: t('Spain'),
    zipcodeChecker: (zipcode) => zipcode?.length === 5,
    zipcodePlaceholder: 'e.g. 28001 or 08001',
    phonePrefix: '34',
    phoneLength: 9,
  },
  {
    id: 'AT',
    name: t('Austria'),
    zipcodeChecker: (zipcode) => zipcode?.length === 4,
    zipcodePlaceholder: 'e.g. 1010 or 6020',
    phonePrefix: '43',
    phoneLength: 10,
  },
  {
    id: 'DK',
    name: t('Denmark'),
    zipcodeChecker: (zipcode) => zipcode?.length === 4,
    zipcodePlaceholder: 'e.g. 1050 or 8000',
    phonePrefix: '45',
    phoneLength: 8,
  },
  {
    id: 'EE',
    name: t('Estonia'),
    zipcodeChecker: (zipcode) => zipcode?.length === 5,
    zipcodePlaceholder: 'e.g. 10111 or 80041',
    phonePrefix: '372',
    phoneLength: 8,
  },
  // {
  //   id: 'GL',
  //   name: t('Greenland'),
  //   zipcodeChecker: (zipcode) => zipcode?.length === 4,
  //   zipcodePlaceholder: 'e.g. 3900 or 3950',
  //   phonePrefix: '299',
  //   phoneLength: 6,
  // },
  {
    id: 'HU',
    name: t('Hungary'),
    zipcodeChecker: (zipcode) => zipcode?.length === 4,
    zipcodePlaceholder: 'e.g. 1051 or 6720',
    phonePrefix: '36',
    phoneLength: 9,
  },
  // {
  //   id: 'FO',
  //   name: t('Faroe Islands'),
  //   zipcodeChecker: (zipcode) => zipcode?.length === 3,
  //   zipcodePlaceholder: 'e.g. 100 or 175',
  //   phonePrefix: '298',
  //   phoneLength: 6,
  // },
  {
    id: 'LV',
    name: t('Latvia'),
    zipcodeChecker: (zipcode) => zipcode?.length === 4,
    zipcodePlaceholder: 'e.g. 1050 or 8000',
    phonePrefix: '371',
    phoneLength: 8,
  },
  {
    id: 'LI',
    name: t('Liechtenstein'),
    zipcodeChecker: (zipcode) => zipcode?.length === 4,
    zipcodePlaceholder: 'e.g. 9490 or 9497',
    phonePrefix: '423',
    phoneLength: 7,
  },
  {
    id: 'LT',
    name: t('Lithuania'),
    zipcodeChecker: (zipcode) => zipcode?.length === 4,
    zipcodePlaceholder: 'e.g. 0110',
    phonePrefix: '370',
    phoneLength: 8,
  },
  {
    id: 'PL',
    name: t('Poland'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{2}-[0-9]{3}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 00-001 or 80-001',
    phonePrefix: '48',
    phoneLength: 9,
  },
  {
    id: 'CZ',
    name: t('Czech Republic'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{3}\s?[0-9]{2}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 100 00 or 602 00',
    phonePrefix: '420',
    phoneLength: 9,
  },
  {
    id: 'SK',
    name: t('Slovakia'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{3}\s?[0-9]{2}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 811 01 or 040 01',
    phonePrefix: '421',
    phoneLength: 9,
  },
  {
    id: 'SI',
    name: t('Slovenia'),
    zipcodeChecker: (zipcode) => zipcode?.length === 4,
    zipcodePlaceholder: 'e.g. 1000 or 2000',
    phonePrefix: '386',
    phoneLength: 8,
  },
  {
    id: 'SE',
    name: t('Sweden'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{3}\s?[0-9]{2}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 100 00 or 602 00',
    phonePrefix: '46',
    phoneLength: 9,
  },
  {
    id: 'BG',
    name: t('Bulgaria'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{4}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 1000 or 4000',
    phonePrefix: '359',
    phoneLength: 9,
  },
  {
    id: 'CY',
    name: t('Cyprus'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{4}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 1000 or 4000',
    phonePrefix: '357',
    phoneLength: 8,
  },
  {
    id: 'HR',
    name: t('Croatia'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{5}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 10000 or 40000',
    phonePrefix: '385',
    phoneLength: 9,
  },
  {
    id: 'FI',
    name: t('Finland'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{5}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 00100 or 40000',
    phonePrefix: '358',
    phoneLength: 9,
  },
  {
    id: 'GR',
    name: t('Greece'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{3}\s?[0-9]{2}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 100 00 or 602 00',
    phonePrefix: '30',
    phoneLength: 10,
  },
  // {
  //   id: 'IS',
  //   name: t('Iceland'),
  //   zipcodeChecker: (zipcode) => {
  //     const regex = /^[0-9]{3}$/;
  //     return regex.test(zipcode);
  //   },
  //   zipcodePlaceholder: 'e.g. 101 or 602',
  //   phonePrefix: '354',
  //   phoneLength: 7,
  // },
  {
    id: 'MT',
    name: t('Malta'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[A-Z]{3}\s?[0-9]{4}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. VLT 1234 or VLT1234',
    phonePrefix: '356',
    phoneLength: 8,
  },
  {
    id: 'NO',
    name: t('Norway'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{4}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 1000 or 4000',
    phonePrefix: '47',
    phoneLength: 8,
    unavailable: true,
  },
  {
    id: 'RO',
    name: t('Romania'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{6}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 010101 or 400000',
    phonePrefix: '40',
    phoneLength: 9,
  },
  {
    id: 'IE',
    name: t('Ireland'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]?\s?[0-9][A-Z]{2}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. SW1A 1AA or SW1W 0NY',
    phonePrefix: '353',
    phoneLength: 9,
  },
  {
    id: 'PT',
    name: t('Portugal'),
    zipcodeChecker: (zipcode) => {
      const regex = /^[0-9]{4}-[0-9]{3}$/;
      return regex.test(zipcode);
    },
    zipcodePlaceholder: 'e.g. 1000-001 or 4000-001',
    phonePrefix: '351',
    phoneLength: 9,
    zipcodeHasLetters: true,
  },
];

export default getCountries;
export const defaultCountry: Country = getCountries((str) => str)[0];

export const getCountryById = (id: string): Country | undefined => {
  const countries = getCountries((str) => str);
  return countries.find((country) => country.id === id);
};
