// cSpell:ignore frequentes, ecommerce, evenements, legales, serenite, Tilly
/* eslint-disable max-len */
module.exports = {
  HomePage: {
    url: '/',
    component: 'src/pagesComponents/home/HomePageV5.tsx',
  },

  HomePageTilly: {
    url: '/tilly/',
    component: 'src/pagesComponents/home/HomePageTilly.js',
    seo: {
      title: 'Tilli, Votre réseau d\'artisans français',
      description: 'Nos artisans couturiers, maroquiniers et cordonniers vous accompagnent pour entretenir et sublimer votre garde-robe.',
      english: {
        title: 'Tilli, Your French Artisans Network',
        description: 'Our couturiers, leather craftsmen and cobblers are here to help you maintain and enhance your wardrobe.',
      },
    },
  },

  HowItWorksSEOPage: {
    url: '/comment-fonctionne-tilli-le-service-de-retoucherie-a-paris-et-marseille/',
    component: 'src/pagesComponents/home/HowItWorksPage.tsx',
    seo: {
      title: 'Le service de couturier à domicile : découvrez Tilli',
      description: 'Un service simple, de qualité et transparent : 1. La prise de RDV - 2. Le RDV avec votre Tilliste - 3. Le retour de vos vêtements',
      english: {
        title: 'Discover Tilli, the home tailoring service',
        description: 'A simple, high-quality and transparent service: 1. Make an appointment - 2. Meet your Tilliste - 3. Get your clothes back',
      },
    },
  },

  HowItWorksPage: {
    url: '/comment-fonctionne-tilli-le-service-de-retouche-et-reparation/',
    component: 'src/pagesComponents/home/HowItWorksPage.tsx',
    seo: {
      title: 'Le service de retouche et réparation : découvrez Tilli',
      description: 'Un service simple, de qualité et transparent : 1. La prise de RDV - 2. Le RDV avec votre Tilliste - 3. Le retour de vos pièces',
      english: {
        title: 'Discover Tilli, the alteration and repair service',
        description: 'A simple, high-quality and transparent service: 1. Make an appointment - 2. Meet your Tilliste - 3. Get your items back',
      },
    },
  },

  AboutPage: {
    url: '/a-propos-de-tilli/',
    component: 'src/pagesComponents/home/AboutPage.js',
    seo: {
      title: 'A propos : découvrez l’histoire derrière Tilli',
      description: 'Chez Tilli, nous partageons tous un objectif commun : celui de remettre au goût du jour le métier de couturier en le digitalisant. Le couturier échange directement avec le client pour ne pas perdre en qualité et laisser place à une expérience unique !',
      english: {
        title: 'About: discover the story behind Tilli',
        description: 'At Tilli, we all share a common goal: to bring the profession of couturier up to date by digitising it. The couturier exchanges directly with the customer to maintain quality and provide a unique experience!',
      },
    },
  },

  BeTillistePage: {
    url: '/devenir-couturier/',
    component: 'src/pagesComponents/home/BeTillistePage.js',
    seo: {
      title: 'Devenir Couturier Freelance - Tilli',
      description: 'Tout savoir sur le métier de couturier Tilli. Travaillez quand vous voulez, en toute simplicité. Nous recrutons à Paris, Lille, Strasbourg, Nice, Cannes, Nantes, etc',
      english: {
        title: 'Become a Freelance Couturier - Tilli',
        description: 'Everything you need to know about the profession of Tilli couturier. Work when you want, with complete simplicity. We are recruiting in Paris, Lille, Strasbourg, Nice, Cannes, Nantes, etc.',
      },
    },
  },

  CustomerFeedbacksPage: {
    url: '/avis-de-nos-clients/',
    component: 'src/pagesComponents/home/CustomerFeedbacksPage.js',
  },

  LegalTerms: {
    url: '/mentions-legales/',
    component: 'src/pagesComponents/home/LegalTerms.js',
    seo: { title: 'Mentions Légales & Conditions Générales - Tilli' },
  },

  CookiesPrivacy: {
    url: '/politique-cookies/',
    component: 'src/pagesComponents/home/CookiesPrivacy.js',
    seo: { title: 'Politiques Cookies - Tilli' },
  },

  ShopifyPrivacy: {
    url: '/tilli-app-privacy-policy/',
    component: 'src/pagesComponents/home/ShopifyPrivacy.js',
    seo: { title: 'Tilli Privacy Policy' },
  },

  Login: {
    url: '/se-connecter/',
    component: 'src/pagesComponents/myAccount/Login.js',
    seo: {
      title: 'Se connecter - Tilli',
      description: 'Se connecter à son compte Tilli. Vous pouvez aussi télécharger notre appli pour suivre vos commandes.',
    },
  },

  ForgottenPasswordRequest: {
    url: '/mot-de-passe-oublie/',
    component: 'src/pagesComponents/myAccount/ForgottenPasswordRequest.js',
    seo: { title: 'Mot de passe oublié - Tilli' },
  },

  ResetPassword: {
    // let the url without trailing slash, otherwise the page didn't load
    url: '/nouveau-mot-de-passe',
    component: 'src/pagesComponents/myAccount/ResetPasswordContainer.js',
    seo: { title: 'Nouveau mot de passe - Tilli' },
  },

  Insurance: {
    url: '/assurance-MMA-en-toute-serenite/',
    component: 'src/pagesComponents/home/Insurance.js',
    seo: {
      title: 'Tous vos vêtements assurés par MMA - Tilli',
      description: 'Toutes les commandes passées chez Tilli sont assurées par notre partenaire MMA. Tilli prend soin de ses clients et couturiers !',
    },
  },

  InsuranceTilliste: {
    url: '/assurance-MMA-tilliste/',
    component: 'src/pagesComponents/home/InsuranceTilliste.js',
    seo: {
      title: 'Tous nos tillistes sont couverts par MMA - Tilli',
      description: 'En tant que tilliste, travaillez sans crainte grâce à notre partenaire MMA. Toutes vos missions sont couvertes par MMA.',
    },
  },

  EventsPage: {
    url: '/nos-evenements/',
    component: 'src/templates/EventsTemplate.js',
    seo: {
      title: 'Nos événements - Tilli',
      photo: '/eventPage.jpg',
      description: 'Atelier broderie, transformation de robe de mariée, retouches et réparations en faveur du Green Friday, création d’accessoires sur-mesure... Pour préparer les fêtes, nos Tillistes sont sur tous les fronts et multiplient les activités en boutiques chez nos marques partenaires.',
    },
  },

  PartnersPage: {
    url: '/ils-nous-font-confiance-les-partenaires-tilli/',
    component: 'src/pagesComponents/home/PartnersPage.js',
    seo: {
      title: 'Nos partenaires - Tilli',
      description: 'Ils font confiance à Tilli pour leurs retouches : Madura, Sézane, Isabel Marant, Violette Sauvage et bien d’autres',
      english: {
        title: 'Our partners - Tilli',
        description: 'They trust Tilli for their alterations: Madura, Sézane, Isabel Marant, Violette Sauvage and many others',
      },
    },
  },

  WhyBecomePartner: {
    url: '/tilli-votre-partenaire-retouche-reparation-ecommerce-boutique/',
    component: 'src/pagesComponents/home/WhyBecomePartner.tsx',
    seo: {
      title: 'Nous choisir comme partenaire retouches - Tilli',
      description: 'Vous êtes une marque ou une boutique, pourquoi choisir Tilli comme partenaire retouches ? Pour améliorer l’expérience client, pour simplifier votre gestion, et plus…',
      english: {
        title: 'Why choose us as a partner for alterations?',
        description: 'You are a brand or a shop, why choose Tilli as a partner for alterations? To improve the customer experience, to simplify your management, and more...',
      },
    },
  },

  FAQPage: {
    url: '/les-questions-les-plus-frequentes-tilli-retouche/',
    component: 'src/pagesComponents/home/FAQPage.js',
    seo: {
      title: 'FAQ : les questions les plus fréquentes - Tilli',
      description: 'Retouches, délai de livraison, villes couvertes ? Toutes les réponses à vos question sur le service Tilli',
    },
  },

  Invite: {
    url: '/i/',
    component: 'src/pagesComponents/home/Invite.js',
    seo: {
      title: '5€ offerts sur votre première commande Tilli',
      photo: '/photo-header-referral-code.jpg',
      description: 'Tilli, Votre réseau d‘artisans français',
      english: {
        title: '5€ off on your first Tilli order',
        photo: '/photo-header-referral-code.jpg',
        description: 'Tilli, Your French Artisans Network',
      },
    },
    isRouteNotIndexedBySearchEngine: true,
  },

  HomePageClacDesDoigts: {
    url: '/clac-des-doigts/',
    component: 'src/pagesComponents/home/HomePageClacDesDoigts.js',
  },

  PricingsPage: {
    url: '/nos-tarifs/',
    component: 'src/pagesComponents/home/PricingsPage.js',
    seo: {
      title: 'Prix Retouche : Tarifs des Retouche de Vêtements - Tilli',
      description: 'Tarifs & Prix des Retouches Vêtements : Découvrez tous nos prix pour la retouche de vos vêtements & Rideaux ✅Couturier 7/7 de 7h à 22h',
      english: {
        title: 'Alterations Prices: Clothing Alterations Prices - Tilli',
        description: 'Clothing Alterations Prices: Discover all our prices for the alteration of your clothes & Curtains ✅Seamstress 7/7 from 7am to 10pm',
      },
    },
  },

  PricingsPageFull: {
    url: '/tous-nos-tarifs/',
    component: 'src/pagesComponents/home/PricingsPageFull.js',
    seo: {
      title: 'Prix Retouche : Tarifs des Retouche de Vêtements - Tilli',
      description: 'Tarifs & Prix des Retouches Vêtements : Découvrez tous nos prix pour la retouche de vos vêtements & Rideaux ✅Couturier 7/7 de 7h à 22h',
      english: {
        title: 'Alterations Prices: Clothing Alterations Prices - Tilli',
        description: 'Clothing Alterations Prices: Discover all our prices for the alteration of your clothes & Curtains ✅Seamstress 7/7 from 7am to 10pm',
      },
    },
  },

  PricingsPageGL: {
    url: '/nos-tarifs-galeries-lafayette/',
    component: 'src/pagesComponents/home/PricingsPageGL.js',
    seo: {
      title: 'Prix Retouche : Tarifs des Retouche de Vêtements - Tilli',
      description: 'Tarifs & Prix des Retouches Vêtements : Découvrez tous nos prix pour la retouche de vos vêtements & Rideaux ✅Couturier 7/7 de 7h à 22h',
    },
  },

  PricingsPageWebView: {
    url: '/nos-tarifs-app/',
    component: 'src/pagesComponents/home/PricingsPageWebView.js',
  },

  OurArtisansPage: {
    url: '/nos-artisans/',
    component: 'src/pagesComponents/home/OurArtisansPage.js',
    seo: {
      title: 'Nos artisans couturiers : leurs paroles - Tilli',
      description: 'Nos Tillistes, artisans couturiers, se livrent sur leur métier et leur relation avec Tilli, notamment leurs meilleurs souvenirs de missions.',
      photo: '/tillistes-board.jpg',
      english: {
        title: 'Our couturiers: their words - Tilli',
        description: 'Our Tillistes, couturiers, talk about their profession and their relationship with Tilli, including their best memories of missions.',
      },
    },
  },

  JobOfferPage: {
    url: '/rejoindre-aventure-tilli/',
    component: 'src/pagesComponents/home/JobOfferPage.js',
    seo: {
      title: 'Rejoindre l’aventure Tilli : toutes nos offres de stage et d’emploi',
      description: 'Tilli recrute ! Consultez toutes nos offres d’emploi en CDI ainsi que nos offres de stage et postulez en ligne.',
    },
  },

  TilliXFitle: {
    url: '/tilli-et-fitle/',
    component: 'src/pagesComponents/home/TilliXFitle.js',
    seo: {
      title: 'Retouche Vêtement Fitle : Tilli Partenaire Fitle – Tilli',
      description: ' Qui n’a jamais eu peur de se tromper de taille en achetant ses vêtements sur Internet ou a été déçu en essayant chez lui son vêtement ? Tilli & Fitle décident donc de réunir leurs forces pour apporter une solution complète aux plateformes e-commerce.',
    },
  },

  LookBookPage: {
    url: '/lookbook-hiver-v1-2019-2020/',
    component: 'src/pagesComponents/home/LookBookPage.js',
    seo: {
      title: 'Lookbook hiver v.1 2019-2020',
      description: 'Depuis la naissance de Tilli, notre ambition est de vous prouver que la retouche, la réparation et l’upcycling est à la fois un moyen de faire plus attention à sa consommation de vêtements et une manière de laisser parler sa créativité ! ',
    },
  },

  LookBookNewsletterPage: {
    url: '/lookbook-hiver-v1-2019-2020-newsletter/',
    component: 'src/pagesComponents/home/LookBookNewsletterPage.js',
    seo: {
      title: 'Lookbook hiver v.1 2019-2020',
      description: 'Depuis la naissance de Tilli, notre ambition est de vous prouver que la retouche, la réparation et l’upcycling est à la fois un moyen de faire plus attention à sa consommation de vêtements et une manière de laisser parler sa créativité ! ',
    },
  },

  SustainableFashionPage: {
    url: '/la-mode-durable/',
    component: 'src/pagesComponents/home/SustainableFashionPage.js',
    seo: {
      title: 'La Mode Durable - Tilli',
      description: 'Expression féminine : prendre le temps de s’amuser et de laisser parler sa créativité pour donner une seconde vie à son dressing et son intérieur !',
      photo: '/last-collab-beryl.jpg',
    },
  },

  Retail: {
    url: '/nos-boutiques/',
    component: 'src/pagesComponents/home/Retail.js',
    seo: {
      title: 'Nos Boutiques - Tilli',
      description: 'Découvrez nos 3 boutiques : rue Henry Monnier, rue Notre Dame de Lorette et au Printemps Haussmann',
      photo: '/boutique.jpg',
    },
  },

  ThanksTillisteFormPage: {
    url: '/merci-inscription-tilliste/',
    component: 'src/pagesComponents/home/ThanksTillisteFormPage.js',
    isRouteNotIndexedBySearchEngine: true,
  },

  MainAlterationClothesPage: {
    url: '/retouche-vetement/',
    component: 'src/pagesComponents/home/LandingMainAlterationClothes.js',
    seo: {
      title: 'Retouche & Couture Vêtement : Retouche & Couture Tous Types de Vêtements - Tilli',
      description: 'Retouche Vêtement : Tilli vous propose un service de Couturier à Domicile pour toutes vos retouches et petites coutures. ✅ Disponible 7/7 de 7h à 22h',
    },
  },

  MainRepairClothesPage: {
    url: '/reparation-vetement/',
    component: 'src/pagesComponents/home/LandingMainRepairClothes.tsx',
    seo: {
      title: 'Réparation & Couture Vêtement : Réparation & Couture Tous Types de Vêtements - Tilli',
      description: 'Réparation Vêtement : Tilli vous propose un service de Couturier à Domicile pour toutes vos réparations et petites coutures. ✅ Disponible 7/7 de 7h à 22h',
    },
  },

  MainLeatherGoodsPage: {
    url: '/maroquinerie/',
    component: 'src/pagesComponents/home/LandingMainLeatherGoods.js',
    seo: {
      title: 'Artisans maroquiniers : Entretiens et réparation de vos accessoires en cuir - Tilli',
      description: "Entretien et réparation de vos accessoires en cuir : Tilli vous propose un service de maroquinerie de qualité disponible de partout grâce à l'envoi de colis.",
    },
  },

  MainShoesPage: {
    url: '/cordonnerie/',
    component: 'src/pagesComponents/home/LandingMainShoes.js',
    seo: {
      title: 'Artisans cordonniers : Entretiens et réparation de vos chaussures - Tilli',
      description: "Entretien et réparation de vos chaussures : Tilli vous propose un service de cordonnerie de qualité disponible de partout grâce à l'envoi de colis.",
    },
  },

  CoutureServicesPage: {
    url: '/nos-services-de-couture/',
    component: 'src/pagesComponents/home/OurServices/CoutureServicesPage.js',
    seo: {
      title: 'Nos Services de couture - Tilli',
      description: 'Retouche et réparations de tous vos vêtements: ourlet, ajustement, transformation, etc.',
      english: {
        title: 'Our Alterations Services - Tilli',
        description: 'Alterations and repairs of all your clothes: hem, adjustment, transformation, etc.',
      },
    },
  },

  LeatherGoodsServicesPage: {
    url: '/nos-services-de-maroquinerie/',
    component: 'src/pagesComponents/home/OurServices/LeatherGoodsServicesPage.js',
    seo: {
      title: 'Nos Services de maroquinerie - Tilli',
      description: 'Entretien et réparation de tous vos accessoires en cuir: sac, ceinture, chaussures, etc.',
      english: {
        title: 'Our Leather Goods Services - Tilli',
        description: 'Maintenance and repair of all your leather accessories: bag, belt, shoes, etc.',
      },
    },
  },

  EmbroideryServicesPage: {
    url: '/nos-services-de-broderie/',
    component: 'src/pagesComponents/home/OurServices/EmbroideryServicesPage.js',
    seo: {
      title: 'Nos Services de broderie - Tilli',
      description: 'Personnalisation de tous vos vêtements et accessoires: initiales, logo, etc.',
      english: {
        title: 'Our Embroidery Services - Tilli',
        description: 'Customization of all your clothes and accessories: initials, logo, etc.',
      },
    },
  },

  ShoesServicesPage: {
    url: '/nos-services-de-cordonnerie/',
    component: 'src/pagesComponents/home/OurServices/ShoesServicesPage.js',
    seo: {
      title: 'Nos Services de cordonnerie - Tilli',
      description: 'Entretien et réparation de tous vos souliers: talon, patin, cirage, etc.',
      english: {
        title: 'Our Shoes Services - Tilli',
        description: 'Maintenance and repair of all your shoes: heel, pad, polish, etc.',
      },
    },
  },

  HomePagePackage: {
    url: '/service-colis/',
    component: 'src/pagesComponents/home/HomePagePackage.js',
    seo: {
      title: 'Tilli - Service de couturiers en envoi de colis',
      description: 'Un service simple, de qualité et transparent.',
      english: {
        title: 'Tilli - Tailor service with package delivery',
        description: 'A simple, high-quality and transparent service.',
      },
    },
  },

  RepairBonusPage: {
    url: '/bonus-reparation/',
    component: 'src/pagesComponents/home/RepairBonusPage.js',
    seo: {
      title: 'Bonus Réparation - Tilli',
      description: 'Tilli vous propose un bonus réparation pour vous encourager à réparer plutôt que de jeter vos vêtements.',
    },
  },

  LandingCurtainsPage: {
    url: '/retouche-rideaux/',
    component: 'src/templates/LandingCurtainsPage.tsx',
    seo: {
      title: 'Retouche Rideaux & Voilages à Domicile - Tilli',
      description: 'Retouche Rideaux & Voilages : Tilli vous envoie un Couturier à Domicile pour Récupérer vos Rideaux pour les Retoucher selon votre demande ✅7/7 de 7h à 22h',
    },
  },
};
